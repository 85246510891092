var Our50th = (function() {
  var deviceType = "";

  // constructor
  var Our50th = function($) {
    //console.log("Our50th Constructor");

    this.$html = $("html");
    this.$window = $(window);

    this.ligthboxItemLength = 0;
    this.currentLightBoxIndex = 0;
    this.$ = $;
    this.numberArray = [];

    if(this.$html.hasClass( "pc" ) ) {
      deviceType = "pc";
    }

    if(this.$html.hasClass( "mobile" ) ) {
      deviceType = "mobile";
    }

    this.$lightbox = $(".gallery-lightbox");
    this.$lightboxVideo = $(".video-lightbox");
    this.$html = $("html");

    // pc or mobile
    if(deviceType === "pc") {
      this.initMasonry($);
      // this.initMobileDropDown($);
    } else {
      this.initMasonry($);
      // this.initMobileDropDown($);
    }
    this.loadMore($);

    //console.log("Our50th Constructor");

    this.initVideo($);
    this.initThumbnail($);
    this.initTab($);
  };

  var o = Our50th.prototype;

  o.initMasonry = function($) {
    $(".grid-thumbnail.gallery").imagesLoaded().progress( function() {
      $(".grid-thumbnail.gallery").masonry({
        // options
        columnWidth: ".grid-sizer",
        gutter: ".gutter-sizer",
        itemSelector: ".grid-item",
        percentPosition: true
      });
    });
  };

  o.loadMore = function($) {
    $(".load-more a").on("click", function() {
      $('.grid-thumbnail.gallery .grid-item.hide:lt(6)').removeClass('hide');
      $(".grid-thumbnail.gallery").masonry('layout');

      if($('.grid-thumbnail.gallery .grid-item.hide').length===0) {
        $(this).parent().remove();
      }

      return false;
    });
  };

  o.resizeVideo = function($) {
    var $video = $('.iframe-wrapper');

    $video.css({ width:'100%', marginTop:0 });

    var $videoHeight = $video.outerHeight(),
        $containerHeight = $('.video-lightbox .lightbox-inner-box').outerHeight();

    if($videoHeight > $containerHeight) {
      var $width = $('.video-lightbox .lightbox-inner-box').outerHeight()*(16/9);
      $video.css({ width:$width });
    } else {
      var $marginTop = ($containerHeight-$videoHeight)/2;
      $video.css({ marginTop:$marginTop });
    }
  };

  o.initVideo = function($) {
    $parent = this;

    $(".watch-video a").click(function() {
    }, function() {
      var $vimeo_id = $(this).data('vimeo');
      $parent.showVideoLightBox($, $vimeo_id);
    });

    $parent.resizeVideo($);
    $parent.$window.resize(function() {
      $parent.resizeVideo($);
    });

    return false;
  };

  o.initTab = function($) {
    $(".quote-tab li a").on("click", function() {
      $("#tabBoxes div").hide();
      $(".quote-tab li a").removeClass("active tabBoxLink1");
      $(this).addClass("active");
      $($(this).attr("href")).fadeToggle();
      return false;
    });
  };

  o.initThumbnail = function($) {
    $parent = this;
    $(".grid-thumbnail.gallery .thumbnail-cover-on-inner").click( function(){
        // $(this).css("background-color", "#F00");
      },
      function(){
        var num = $(this).attr( "data-id" );
        //console.log(num + "data-id!");
        $parent.showLightBox($, num);
      });
    return false;
  };

  o.showVideoLightBox = function($, $vimeo_id) {
    $parent = this;

    var scrollTop = $parent.$window.scrollTop();

    WindowUtility.disableScroll();

    $(".video-lightbox iframe").attr("src", "https://player.vimeo.com/video/"+$vimeo_id+"?autoplay=1");
    $parent.$lightboxVideo.addClass("show");
    $parent.resizeVideo($);

    $("#video-lightbox-close").on("click", function (e) {
      $parent.hideVideoLightBox();
      e.preventDefault();
    });
  };

  o.hideVideoLightBox = function() {
    $parent = this;

    $(".video-lightbox iframe").attr("src", "");
    $parent.$lightboxVideo.removeClass("show");

    WindowUtility.enableScroll();
  };

  o.showLightBox = function($, num) {
    var scrollTop = this.$window.scrollTop();

    this.ligthboxItemLength = $( ".lightbox-item" ).length;
    //console.log(this.ligthboxItemLength + " item we have");

    $parent = this;
    $(".lightbox-item").each(function( index ) {
      var dataId = $(this).attr( "data-id" );
      $parent.numberArray.push(dataId);
      //console.log(this + "this");
    });

    WindowUtility.disableScroll();
    //$('body').addClass('u-no-scroll');
    // WindowUtility.hideScroll();

    // this.$lightbox.css( "height", "100vh" );
    // this.$lightbox.css( "top", scrollTop + "px" );
    this.$lightbox.addClass("show");

    this.$("#lightbox" + num).addClass("show");
    // this.currentLightBoxIndex = num;

    $parent = this;
    $("#lightbox-close").on("click", function (e) {
      $parent.hideLightBox();
      e.preventDefault();
    });

    $(".svg-leftbutton").on("click", function (e) {
      $parent.currentLightBoxIndex--;
      $parent.updateLightBoxAsstes();
      e.preventDefault();
    });

    $(".svg-rightbutton").on("click", function (e) {
      $parent.currentLightBoxIndex++;
      $parent.updateLightBoxAsstes();
      e.preventDefault();
    });
  };

  o.hideLightBox = function() {
    this.$lightbox.removeClass("show");
    this.$(".lightbox-item").removeClass("show");
    WindowUtility.enableScroll();
    //$('body').removeClass('u-no-scroll');
    // WindowUtility.restoreScroll();
  };

  o.updateLightBoxAsstes = function() {
    var index = this.generateIndex(this.currentLightBoxIndex , this.ligthboxItemLength - 1);
    var id = this.numberArray[index];
    this.currentLightBoxIndex = index;
    this.$(".lightbox-item").removeClass("show");
    this.$("#lightbox" + id).addClass("show");
  };

  o.initMobileDropDown = function($) {
   // console.log("initMobileDropDown99");

    $("ul.mobile-dropdown-list li").click(function(){
      if( $(this).hasClass("open")) {
        $(this).removeClass("open");
        $("ul.mobile-dropdown-list li ul").slideUp(150);
      } else {
        $(this).addClass("open");
        $("ul.mobile-dropdown-list li ul").slideDown(150);
      }
    });
  };

  o.generateIndex = function(current, max) {

    if(current > max) {
      current = 0;
    } else if (current < 0) {
      current = max;
    }
    return current;
  };

  $(".slick-slider").slick({
    arrows: true,
    infinite: true,
    dots: true,
  });

  return Our50th;
})();

