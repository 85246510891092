var Quote = (function() {
  // constructor
  var Quote = function($) {
    //console.log("Quote Constructor");
    var open = false;

    // For Ninja forms
    // https://stackoverflow.com/questions/40875677/custom-jquery-not-working-on-wordpress-ninja-forms-plugin
    jQuery(document).on( 'nfFormReady', function( e, layoutView ) {
      // console.log("nfFormReady is Ready");

      $("#quoteMore").on("click", function() {
        if(open) {
          $(this).removeClass('toggled');
          $("#nf-field-21-container").removeClass("show");
          $("#nf-field-11-container").removeClass("show");
          $("#nf-field-12-container").removeClass("show");
          $("#nf-field-13-container").removeClass("show");
          $("#nf-field-14-container").removeClass("show");
          $("#nf-field-15-container").removeClass("show");
          $("#nf-field-17-container").removeClass("show");
          $("#nf-field-18-container").removeClass("show");
          $("#nf-field-24-container").removeClass("show");
          $("#nf-field-25-container").removeClass("show");
          $("#nf-field-26-container").removeClass("show");
          $("#nf-field-27-container").removeClass("show");
          $("#nf-field-28-container").removeClass("show");
          $("#nf-field-29-container").removeClass("show");
          $("#nf-field-30-container").removeClass("show");
          $("#nf-field-31-container").removeClass("show");
          $("#nf-field-48-container").removeClass("show");
          $("#nf-field-49-container").removeClass("show");
          $("#nf-field-50-container").removeClass("show");
          $("#nf-field-51-container").removeClass("show");
          $(".field-specs").removeClass("show");
          open = false;
        } else {
          $(this).addClass('toggled');
          $("#nf-field-21-container").addClass("show");
          $("#nf-field-11-container").addClass("show");
          $("#nf-field-12-container").addClass("show");
          $("#nf-field-13-container").addClass("show");
          $("#nf-field-14-container").addClass("show");
          $("#nf-field-15-container").addClass("show");
          $("#nf-field-17-container").addClass("show");
          $("#nf-field-18-container").addClass("show");
          $("#nf-field-24-container").addClass("show");
          $("#nf-field-25-container").addClass("show");
          $("#nf-field-26-container").addClass("show");
          $("#nf-field-27-container").addClass("show");
          $("#nf-field-28-container").addClass("show");
          $("#nf-field-29-container").addClass("show");
          $("#nf-field-30-container").addClass("show");
          $("#nf-field-31-container").addClass("show");
          $("#nf-field-48-container").addClass("show");
          $("#nf-field-49-container").addClass("show");
          $("#nf-field-50-container").addClass("show");
          $("#nf-field-51-container").addClass("show");
          $(".field-specs").addClass("show");
          open = true;
        }
        return false;
      });
    });
  };

  var q = Quote.prototype;
  return Quote;
})();
