var $ = jQuery;

function WindowUtility () {
}

WindowUtility.init = function () {
  this.$html = $('html');
  this.$window = $(window);
  this.$header= $('header.banner');
};

WindowUtility.resize = function () {
  // console.log('Window resize');
  // if( $(window).width() < 1024) {
  //   UAParserUtil._setDeviceType('mobile')
  // } else {
  //   UAParserUtil._setDeviceType('pc')
  // }
};

WindowUtility.scroll = function () {
  // console.log('scroll' + window.scrollY);
  if( window.scrollY > 50) {
    MenuUtility.headerBGUpdate(true);
  } else {
    MenuUtility.headerBGUpdate(false);
  }
};

WindowUtility.setHeight = function($) {
  const h = this.$window.innerHeight();
};

WindowUtility.getHeight = function() {
  var h = this.$window.innerHeight();
  return h;
};

WindowUtility.hideScroll = function() {
  // スクロールバー出さない
  this.$html.css( 'height','100%' );
  this.$html.css( 'overflow-y','hidden' );
};

WindowUtility.restoreScroll = function() {
  // スクロールバー出さない
  this.$html.css( 'height','auto' );
  this.$html.css( 'overflow-y','auto' );
};

WindowUtility.preventDefault = function(e) {
  e = e || window.event;
  if(e.preventDefault) {
    e.preventDefault();
  }
  e.returnValue = false;
};

WindowUtility.disableScroll = function() {
  if(window.addEventListener) {
    window.addEventListener('DOMMouseScroll', this.preventDefault, false);
  }
  if(document.addEventListener) {
    document.addEventListener('touchmove', this.preventDefault, false);
  }
  window.onwheel = this.preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
  window.ontouchmove  = this.preventDefault; // mobile
}

WindowUtility.enableScroll = function() {
  if(window.removeEventListener) {
    window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
  }
  if(document.removeEventListener) {
    document.removeEventListener('touchmove', this.preventDefault, false);
  }
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
}