function ParallaxUtility () {
}

ParallaxUtility.skrollrInit = function () {
  // console.log('skrollrInit');

  var s = skrollr.init({
    smoothScrolling: false,
    mobileDeceleration: 0.004
  });

  if (s.isMobile()) {
      s.destroy();
  }

};
