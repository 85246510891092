/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/*jshint ignore: start*/

/*! js-cookie v3.0.0-beta.4 | MIT */
!function(e,t){"object"==typeof exports&&"undefined"!=typeof module?module.exports=t():"function"==typeof define&&define.amd?define(t):(e=e||self,function(){var r=e.Cookies,n=e.Cookies=t();n.noConflict=function(){return e.Cookies=r,n}}())}(this,function(){"use strict";function e(e){for(var t=1;t<arguments.length;t++){var r=arguments[t];for(var n in r)e[n]=r[n]}return e}var t={read:function(e){return e.replace(/%3B/g,";")},write:function(e){return e.replace(/;/g,"%3B")}};return function r(n,i){function o(r,o,u){if("undefined"!=typeof document){"number"==typeof(u=e({},i,u)).expires&&(u.expires=new Date(Date.now()+864e5*u.expires)),u.expires&&(u.expires=u.expires.toUTCString()),r=t.write(r).replace(/=/g,"%3D"),o=n.write(String(o),r);var c="";for(var f in u)u[f]&&(c+="; "+f,!0!==u[f]&&(c+="="+u[f].split(";")[0]));return document.cookie=r+"="+o+c}}return Object.create({set:o,get:function(e){if("undefined"!=typeof document&&(!arguments.length||e)){for(var r=document.cookie?document.cookie.split("; "):[],i={},o=0;o<r.length;o++){var u=r[o].split("="),c=u.slice(1).join("="),f=t.read(u[0]).replace(/%3D/g,"=");if(i[f]=n.read(c,f),e===f)break}return e?i[e]:i}},remove:function(t,r){o(t,"",e({},r,{expires:-1}))},withAttributes:function(t){return r(this.converter,e({},this.attributes,t))},withConverter:function(t){return r(e({},this.converter,t),this.attributes)}},{attributes:{value:Object.freeze(i)},converter:{value:Object.freeze(n)}})}(t,{path:"/"})});

/*jshint ignore: end*/


(function($) {
  var deviceType;
  var browserName;
  var home;
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    "common": {
      init: function() {
        // JavaScript to be fired on all pages
        //console.log("common");

        // set device type
        UAParserUtil._parse($);
        // var ua = new UAParserUtil($);
        // var type = ua.getDeviceType();
        deviceType = UAParserUtil.getDeviceType();
        browserName = UAParserUtil._getBrowserName();

        var c = new Common();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

const jsCookieAccept = function(){
      // ######################################## COOKIE EVENT
      // this is my cookie events

      const html = document.querySelector('html');
        //Cookies.remove('acceptballetnotify2020');
        var cookieWrapper = document.querySelector('.js--cookie-notification');
        if( cookieWrapper ){
          var acceptButton = cookieWrapper.querySelector('.accept');
          var closeButton = cookieWrapper.querySelector('.close');
          html.classList.add('is--cookie__notaccepted');
          acceptButton.addEventListener('click',function(e){
            Cookies.set('accepthemlocknotify2020', '1', { expires: 2 });
            //html.classList.add('is--cookie__accepted');
            html.classList.remove('is--cookie__notaccepted');
          });

        // cookieWrapper.addEventListener('click',function(e){
        //  e.stopPropagation();
        //  Cookies.set('acceptballetnotify', '1', { expires: 30 });
        //  //html.classList.add('is--cookie__accepted');
        //  html.classList.remove('is--cookie__notaccepted');
        // });

          closeButton.addEventListener('click',function(e){
            //e.stopPropagation();
            Cookies.set('accepthemlocknotify2020', '1', { expires: 2 });
            //html.classList.add('is--cookie__accepted');
            html.classList.remove('is--cookie__notaccepted');
          });


          if( Cookies.get('accepthemlocknotify2020') === '1' ){
            //html.classList.add('is--cookie__accepted');
            html.classList.remove('is--cookie__notaccepted');
          }else{
            //html.classList.remove('is--cookie__accepted');
            html.classList.add('is--cookie__notaccepted');
          }
        }
      };
      jsCookieAccept();



        $('a[href*="#"]:not([href="#"]):not(.tabBoxLink)').on('click', function() {
          if ( location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname && !$(this).hasClass("no-move") ) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=tab_' + this.hash.slice(1) +']');
            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top - 300
              }, 800);
              return false;
            }
          }
        });


        function hasTouch() {
            return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        }

        if (hasTouch()) { // remove all :hover stylesheets
            try { // prevent exception on browsers not supporting DOM styleSheets properly
                for (var si in document.styleSheets) {
                    var styleSheet = document.styleSheets[si];
                    if (!styleSheet.rules) continue;

                    for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                        if (!styleSheet.rules[ri].selectorText) continue;

                        if (styleSheet.rules[ri].selectorText.match(':hover')) {
                            styleSheet.deleteRule(ri);
                        }
                    }
                }
            } catch (ex) {}
        }

        $("ul.mobile-dropdown-list > li").click(function(){
          if( $(this).hasClass("open")) {
            $(this).removeClass("open");
            $("ul.mobile-dropdown-list li ul").slideUp(150);
            //console.log('test');
          } else {
            $(this).addClass("open");
            $("ul.mobile-dropdown-list li ul").slideDown(150);
          }
        });

        $("ul.mobile-dropdown-list li li").click(function(){
          $("ul.mobile-dropdown-list li li").removeClass("active").removeAttr('style');
          $(this).addClass("active").hide();
        });

        $companyMenuTrigger = $('.menu-company');
        $companyMenu = $('.company-menu-container');

        $companyMenuTrigger.hover(function() {
          $companyMenu.toggleClass('menu-is-hidden');
          //console.log('show the menu');
        });

        if($('body').hasClass('tax-portfolio-category')) {
          $(window).load(function(){
            var p = new Portfolio($);
          });
        }

        if($('body').hasClass('single-services') && deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
    // Home page
    "home": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("home");
        home = new Home();
        if (deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
          // console.log(browserName);
        }
      },
      resize: function() {
        // JavaScript to be fired on the home page
        //console.log("home resize");
        home.resize();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Services
   "services": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("services");
        var s = new Services($);
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
        $(window).load(function(){
          var p = new Portfolio($);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Portfolio
    "portfolio": {
      init: function() {
        // JavaScript to be fired on the portfolio page
        //console.log("portfolio");
        // var ua = new UAParserUtil($);
        // var type = ua.getDeviceType();
        // deviceType = type;
        $(window).load(function(){
          var p = new Portfolio($);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Publications
    "publications": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("publications");
        var p = new Portfolio($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    "marketing_materials": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("marketing-materials");
        var p = new Portfolio($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    "display": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("display");
        var p = new Portfolio($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    "packaging": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("packaging");
        var p = new Portfolio($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    "books_catalogues": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("books-catalogues");
        var p = new Portfolio($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    "books": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("books");
        var p = new Portfolio($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    "fine_art": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("catalogues");
        var p = new Portfolio($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    "stationery": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("stationery");
        var p = new Portfolio($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Submit
    "submit_files": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("submit");
        var s = new Submit($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // FAQ
    "faq": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("faq");
        var s = new Submit($);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Submit
    "employment": {
      init: function() {
        // JavaScript to be fired on the home page
       // console.log("employment");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    "careers": {
      init: function() {
        // JavaScript to be fired on the home page
       // console.log("employment");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    "about_us": {
      init: function() {
        //console.log("about us");
        var c = new AboutUs($);
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }

        $(window).load(function(){
          var p = new Portfolio($);
        });

        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        var teamMenu = $('#portfolio-menu-list'),
            teamMenuLink = $('a',teamMenu);

            teamMenuLink.on('click',function(e){
              e.preventDefault();
              $('li',teamMenu).removeClass('active');
              $(this).parent().addClass('active');
              window.location.hash = $(this).data('slug');
            });

        $(window).on('hashchange',function(e){
            var selector = window.location.hash.replace('#','.');
            $('.grid-thumbnail').removeClass('is--active');

            console.log(selector);

            $('.mobile-dropdown-list .first').html(selector.replace('-',' ').replace('-',' ').replace('.',''));


            $(selector).addClass('is--active');
            // $(selector).masonry('reloadItems');
            // $(selector).masonry('reloadItems');
            $(selector).imagesLoaded().progress( function() {
              $(selector).masonry({
                // options
                columnWidth: ".grid-sizer",
                gutter: ".gutter-sizer",
                itemSelector: ".grid-item",
                horizontalOrder: true,
                percentPosition: true
              });
            });

        });

      }
    },
    "history": {
      init: function() {
        //console.log("history");
        var c = new AboutUs($);
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
    "sustainability": {
      init: function() {
        //console.log("sustainability");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
    "sustainable_operations": {
      init: function() {
        //console.log("sustainability");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
     "print_options": {
      init: function() {
        //console.log("sustainability");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
    "social_purpose": {
      init: function() {
        //console.log("sustainability");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
    "carbon_neutrality_zero": {
      init: function() {
        //console.log("carbon");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
    "technology": {
      init: function() {
        //console.log("technology");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
    "recognition": {
      init: function() {
        //console.log("recognition");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      }
    },
    "blog": {
      init: function() {
        //console.log("recognition");
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }

        //console.log("initMobileDropDown99");

        // $("ul.mobile-dropdown-list li").click(function(){
        //   if( $(this).hasClass("open")) {
        //     $(this).removeClass("open");
        //     $("ul.mobile-dropdown-list li ul").slideUp(150);
        //   } else {
        //     $(this).addClass("open");
        //     $("ul.mobile-dropdown-list li ul").slideDown(150);
        //   }
        // });
      }
    },
    // About us page, note the change from about-us to about_us.
    "get_a_quote": {
      init: function() {
        // console.log("quote");
        var c = new Quote($);
        // JavaScript to be fired on the about us page
      }
    },
    // About us page, note the change from about-us to about_us.
    "single_post": {
      init: function() {
        //console.log("Post");
        var c = new Post($);
        // JavaScript to be fired on the about us page
      }
    },
    // Our 50th
    "our_50th": {
      init: function() {
        // JavaScript to be fired on the home page
        //console.log("Our 50th");
        var o = new Our50th($);
        if(deviceType === "pc" && browserName === 'notIE') {
          ParallaxUtility.skrollrInit();
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    "page_template_content_page_our_50th_story": {
      init: function() {
        //console.log("Story");
        var c = new Post($);
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    callFire: function(funcname) {
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm, funcname);
        // console.log(classnm);
        UTIL.fire(classnm, "finalize");
      });
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire("common");

      // Call function
      UTIL.callFire("init");

      // Fire common finalize JS
      UTIL.fire("common", "finalize");

      // Resize
      $(window).resize(_.debounce(function(){
        UTIL.callFire("resize");
        WindowUtility.resize($);
      }, 500));
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  // URL with Hash
  // right away before the browser can start scrolling!
  var elemTarget = window.location.hash;

  // delete hash so the page won't scroll to it
  window.location.hash = '';

  // now whenever you are ready do whatever you want
  // (in this case I use jQuery to scroll to the tag after the page has loaded)
  $(window).on('load', function() {
    if (elemTarget) {
      $('html, body').animate({
        scrollTop: $(elemTarget).offset().top - 198,
      }, 700);
      if(history.pushState) {
        history.pushState(null, null, elemTarget);
      }
      else {
        window.location.hash = elemTarget;
      }
    }
  });


})(jQuery); // Fully reference jQuery after this point.
