var $ = jQuery;

function MenuUtility () {
}

MenuUtility.indexAccrdionMenu = function () {
  //console.log("indexAccrdionMenu");

  var reset = 0;

  function resizedw(){
    if(window.matchMedia("(max-width: 1024px)").matches) {
      if(reset === 0) {
        $('.nav-primary .nav>li.menu-item-has-children>a').on('click', function(e) {
          e.preventDefault();
          $(this).next('.sub-menu').slideToggle(300);
          $(this).toggleClass('active');
        });
        reset = 1;
      }
    }
    else {
      $('.nav-primary .nav>li.menu-item-has-children>a').off('click');
      reset = 0;
    }
  }
  resizedw();

  var doit;
  window.onresize = function(){
    clearTimeout(doit);
    doit = setTimeout(resizedw, 100);
  };

};

MenuUtility.accrdionMenu = function ($) {
  $(".accordion-menu dt").on("click", function(e) {
    // console.log( $(this).next());
    //console.log( $(this).children("svg"));

    if($(this).is(".active")) {
      $(this).removeClass("active");
    }else {
      $(this).addClass("active");
    }

    $(this).next().slideToggle();
  });
};

MenuUtility.aboutAccrdionMenu = function ($) {
  $("#about-accordion-menu dt").on("click", function(e) {
    $parent = $(e.target).parent();
    //console.log( $(e.target).parent());

    //console.log( $parent.hasClass("active"));

    if($parent.hasClass("active") === true) {
      $parent.removeClass("active");
    }else {
      $parent.addClass("active");
    }

    $(this).next().slideToggle();
  });
};


MenuUtility.hunbergerMenu = function () {
  this.$indexMenu = $(".nav-wrap");
  this.$normalLogo = $("#normalLogo");
  this.$window = $(window);
  this.$header= $("header.banner");

  var parent = this;

  //console.log( "parent!!!");

  var h = WindowUtility.getHeight();
  //console.log( h + "= height!!!");

  var scrollpos;

  $("#hamburger").change(function(e) {
    if(this.checked) {
      scrollpos = $(window).scrollTop();

      //console.log( scrollpos + "= scrollpos!!!");

      $("body").addClass("fixed").css({"top": -scrollpos});

      if( (scrollpos > 0) && parent.$header.hasClass("bg-on")) {
        //console.log("= it does!!!");
        parent.$header.addClass("bg-off");
      }

      parent.$indexMenu.css( "top", scrollpos + "px" );
      // parent.$indexMenu.css( "height", h );
      parent.$indexMenu.css( "height", "100%" );
      parent.$indexMenu.addClass("show");
      // parent.$normalLogo.addClass("hide");


    } else {
      parent.$indexMenu.removeClass("show");
      // parent.$normalLogo.removeClass("hide");

      $("body").removeClass("fixed").css({"top": 0});
      window.scrollTo( 0 , scrollpos );

      if(scrollpos > 0) {
        // console.log("= it does!!!");
        parent.$header.removeClass("bg-off");
      }

    }
  });

  this.indexAccrdionMenu($);
};

MenuUtility.hunbergerMenu = function () {
  this.$indexMenu = $(".nav-wrap");
  this.$normalLogo = $("#normalLogo");
  this.$window = $(window);
  this.$header= $("header.banner");

  var parent = this;

  //console.log( "parent!!!");

  var h = WindowUtility.getHeight();
  //console.log( h + "= height!!!");

  var scrollpos;

  $("#hamburger").change(function(e) {
    if(this.checked) {
      scrollpos = $(window).scrollTop();
      //console.log( scrollpos + "= scrollpos!!!");

      $("body").addClass("fixed").css({"top": -scrollpos});
      parent.$header.addClass("bg-menu-on");
      parent.$indexMenu.animate({ scrollTop: 0 }, "fast");
      parent.$indexMenu.addClass("show");
    } else {
      parent.$indexMenu.removeClass("show");
      $("body").removeClass("fixed").css({"top": 0});
      window.scrollTo( 0 , scrollpos );
      parent.$header.removeClass("bg-menu-on");
    }
  });

  this.indexAccrdionMenu($);
};

MenuUtility.headerBGUpdate = function (hasBG) {
  if( hasBG ) {
    this.$header.addClass("bg-on");
  } else {
    this.$header.removeClass("bg-on");
  }
};
