var Comapny = (function() {
  // constructor
  var Comapny = function($) {
      // console.log("Comapny Constructor");
    MenuUtility.accrdionMenu($);
    this.initArrow($);
  };

  var p = Comapny.prototype;

  p.initArrow = function ($) {
    this.$downBtn = $("#service-category-menu a");
    this.$downBtn.on("click", function (e) {
      var num = e.target.getAttribute("data-id");
      TweenMax.to(window, 1, {scrollTo: "#" + num, ease: Power2.easeInOut});
      e.preventDefault();
    });
  };
  return Comapny;
})();
