var Post = (function() {
  // constructor
  var Post = function () {
    //console.log("Post Constructor");

    this.init();
  };

  var p = Post.prototype;

  p.init = function () {
    // console.log("Post init");

    this.initShare();
    this.resizeHero();
  };

  p.resize = function () {
    this.resizeHero();
  };

  p.resizeHero = function () {
  };


  p.initShare = function (intWidth, intHeight, blnResize) {
    this.$shareBtn = $('.customer.share');
    this.$shareBtn.on("click", function (e) {
      // Prevent default anchor event
      e.preventDefault();

      // Set values for window
      intWidth = intWidth || '500';
      intHeight = intHeight || '400';
      strResize = (blnResize ? 'yes' : 'no');

      // Set title and open popup with focus on it
      var strTitle = ((typeof $(this).attr('title') !== 'undefined') ? $(this).attr('title') : 'Social Share');
      var strParam = 'top=0,left=0, width=' + intWidth + ',height=' + intHeight + ',resizable=' + strResize;
      window.open($(this).attr('href'), strTitle, strParam).focus();
    });
  };
  return Post;
})();
