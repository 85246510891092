var Services = (function() {
  // constructor
  var Services = function($) {
    // console.log("Services Constructor");
    MenuUtility.accrdionMenu($);

    // var h = $("#Digital").position();
    // // $(".parallax-wrapper-services").css("top", h.top)
    // // $(".parallax-wrapper-services").css("top", 0)
    // console.log("Services Constructor" + h.top);

    this.initArrow($);
  };

  var p = Services.prototype;

  p.initArrow = function ($) {
    this.$downBtn = $("#service-category-menu a");
    this.$downBtn.on("click", function (e) {
      var num = e.target.getAttribute("data-id");
      TweenMax.to(window, 1, {scrollTo: {y: "#" + num, ease: Power2.easeInOut, offsetY: 198}});
      e.preventDefault();
    });
  };
  return Services;
})();
