var Portfolio = (function() {
  var deviceType = "";

  // constructor
  var Portfolio = function($) {
    //console.log("Portfolio Constructor");

    this.$html = $("html");
    this.$window = $(window);

    this.ligthboxItemLength = 0;
    this.currentLightBoxIndex = 0;
    this.$ = $;
    this.numberArray = [];

    if(this.$html.hasClass( "pc" ) ) {
      deviceType = "pc";
    }

    if(this.$html.hasClass( "mobile" ) ) {
      deviceType = "mobile";
    }

    this.$lightbox = $(".lightbox");
    this.$html = $("html");

    // pc or mobile
    if(deviceType === "pc") {
      this.initMasonry($);
      // this.initMobileDropDown($);
    } else {
      this.initMasonry($);
      // this.initMobileDropDown($);
    }

    //console.log("Portfolio Constructor");

    this.initThumbnail($);
    this.TinitThumbnail($);
    this.initTab($);

  };

  var p = Portfolio.prototype;

  p.initMasonry = function($) {
    $(".grid-thumbnail").imagesLoaded().progress( function() {
      $(".grid-thumbnail").masonry({
        // options
        columnWidth: ".grid-sizer",
        gutter: ".gutter-sizer",
        itemSelector: ".grid-item",
        horizontalOrder: true,
        percentPosition: true
      });
    });
  };

  p.initTab = function($) {
    $(".quote-tab li a").on("click", function() {
      $("#tabBoxes div").hide();
      $(".quote-tab li a").removeClass("active tabBoxLink1");
      $(this).addClass("active");
      $($(this).attr("href")).fadeToggle();
      return false;
    });
  };

  p.initThumbnail = function($) {
    $parent = this;
    $(".thumbnail-cover-on-inner").click( function(){
        // $(this).css("background-color", "#F00");
      },
      function(){
        var num = $(this).attr( "data-id" );
        //console.log(num + "data-id!");
        $parent.showLightBox($, num);
      });
    return false;
  };


  //can't reuse the portfolio scripts because the classes tied to the scripts are also tied to the
  //styling classes. this is a good example of why separating the classes for scripts and theme is a good idea.
  p.TinitThumbnail = function($) {
    $parent = this;
    $('.portfolio-link').click(function(){
      $(this).parents('.grid-item').find('.t-thumbnail-cover-on-inner').click();
    });
    $(".t-thumbnail-cover-on-inner").click( function(){
        // $(this).css("background-color", "#F00");
      },
      function(){

        //show the lightbox ( holy fut seriously no sense of modularity here )
        var num = $(this).attr( "data-id" );
        $parent.showLightBox($, num);


        var thisSlide = $(this).data('cat');
        var thisCount = $(this).data('count');
        var thisSize = $(this).data('size');
        var thisName = $(this).data('name');
        var thisEmail = $(this).data('email');
        var thisPosition = $(this).data('position');

        var thisDescription = $('.description',this).html();
        var thisPhoto = $('.description',this).parent().parent().prev();

        $('.lightbox-inner-box').html('<div><img src="'+thisPhoto[0].src+'" /></div><div><h3>'+thisName+'</h3><span class="position">'+thisPosition+'</span><a href="mailto:'+thisEmail+'">'+thisEmail+'</a>'+thisDescription+'</div>');
        $(".lightbox-item").show();




        $(".t-svg-leftbutton").on("click", function (e) {
          var thisDescription = $('.'+ thisSlide + '-' + (thisCount - 1) + ' .description').html();
          var photo = $('.'+ thisSlide + '-' + (thisCount - 1)).parent().prev();

          var name = $('.'+ thisSlide + '-' + (thisCount - 1)).data('name');
          var email = $('.'+ thisSlide + '-' + (thisCount - 1)).data('email');
          var position = $('.'+ thisSlide + '-' + (thisCount - 1)).data('position');


          $('.lightbox-inner-box').html('<div><img src="'+photo[0].src+'" /></div><div><h3>'+name+'</h3><span class="position">'+position+'</span><a href="mailto:'+email+'">'+email+'</a>'+thisDescription+'</div>');

          var nextCount = thisCount - 1;
          if( nextCount > 1 ){
            thisCount = thisCount - 1;
          }

          e.preventDefault();
        });

        $(".t-svg-rightbutton").on("click", function (e) {

          if( thisCount !== thisSize ){

            var thisDescription = $('.'+ thisSlide + '-' + (thisCount + 1) + ' .description').html();
            var photo = $('.'+ thisSlide + '-' + (thisCount + 1)).parent().prev();
            var name = $('.'+ thisSlide + '-' + (thisCount + 1)).data('name');
            var email = $('.'+ thisSlide + '-' + (thisCount + 1)).data('email');
            var position = $('.'+ thisSlide + '-' + (thisCount + 1)).data('position');

            $('.lightbox-inner-box').html('<div><img src="'+photo[0].src+'" /></div><div><h3>'+name+'</h3><span class="position">'+position+'</span><a href="mailto:'+email+'">'+email+'</a>'+thisDescription+'</div>');
            var nextCount = thisCount + 1;
            if( nextCount < thisSize ){
              thisCount = thisCount + 1;
            }

          }


          e.preventDefault();
        });


      });
    return false;
  };


  p.showLightBox = function($, num) {
    var scrollTop = this.$window.scrollTop();

    this.ligthboxItemLength = $( ".lightbox-item" ).length;
    //console.log(this.ligthboxItemLength + " item we have");

    $parent = this;
    if($parent.numberArray == 0){
      $(".lightbox-item").each(function( index ) {
        var dataId = $(this).attr( "data-id" );
        $parent.numberArray.push(dataId);
        //console.log(this + "this");
      });
    }

    WindowUtility.disableScroll();
    //$('body').addClass('u-no-scroll');
    // WindowUtility.hideScroll();

    // this.$lightbox.css( "height", "100vh" );
    // this.$lightbox.css( "top", scrollTop + "px" );
    this.$lightbox.addClass("show");
    this.currentLightBoxIndex = $parent.numberArray.indexOf(num);
    this.$("#lightbox" + num).addClass("show");
    // this.currentLightBoxIndex = num;
  };

  $parent = this;
  $("#lightbox-close").on("click", function (e) {
    $parent.hideLightBox();
    e.preventDefault();
  });

  $(".svg-leftbutton").on("click", function (e) {
    $parent.currentLightBoxIndex--;
    $parent.updateLightBoxAsstes();
    e.preventDefault();
  });

  $(".svg-rightbutton").on("click", function (e) {
    $parent.currentLightBoxIndex++;
    $parent.updateLightBoxAsstes();
    e.preventDefault();
  });

  p.hideLightBox = function() {
    this.$lightbox.removeClass("show");
    this.$(".lightbox-item").removeClass("show");
    WindowUtility.enableScroll();
    //$('body').removeClass('u-no-scroll');
    // WindowUtility.restoreScroll();
  };

  p.updateLightBoxAsstes = function() {
    var index = this.generateIndex(this.currentLightBoxIndex , this.ligthboxItemLength - 1);
    var id = this.numberArray[index];
    this.currentLightBoxIndex = index;
    this.$(".lightbox-item").removeClass("show");
    this.$("#lightbox" + id).addClass("show");
  };

  p.initMobileDropDown = function($) {
   // console.log("initMobileDropDown99");

    $("ul.mobile-dropdown-list li").click(function(){
      if( $(this).hasClass("open")) {
        $(this).removeClass("open");
        $("ul.mobile-dropdown-list li ul").slideUp(150);

        console.log('wtf');

      } else {
        $(this).addClass("open");
        $("ul.mobile-dropdown-list li ul").slideDown(150);
      }
    });
  };

  p.generateIndex = function(current, max) {

    if(current > max) {
      current = 0;
    } else if (current < 0) {
      current = max;
    }
    return current;
  };

  return Portfolio;
})();
