function UAParserUtil () {
}

UAParserUtil._parse = function($) {
  this.mobile = false;
  this.pc = false;
  this.$ = $;
  var parser = new UAParser();
  var data = parser.getResult();
  var type = data.device.type;
  var browser = data.browser.name;
  this._setDeviceType(type);
  this._setBrowserName(browser);
  this._getBrowserName(browser);
};

UAParserUtil._setDeviceType = function (type) {
  var $html = this.$('html');

  if( (type === 'mobile') || ( type === 'tablet') ) {
    this.mobile = true;
    if (!$html.hasClass('mobile')) {
      $html.addClass('mobile');
      //console.log('test');
    }
  } else {
    if (!$html.hasClass('pc')) {
      $html.addClass('pc');
    }
    this.pc = true;
  }
  // console.log('PC=' + this.pc);
  // console.log('mobile=' + this.mobile);
};

UAParserUtil.getDeviceType = function () {
  if(this.pc) {
    return 'pc';
  }

  if(this.mobile) {
    return 'mobile';
  }
};

UAParserUtil._setBrowserName = function (browser) {
  var $html = this.$('html');

  if( (browser === 'IE') ) {
      $html.addClass('ie');
  }
};

UAParserUtil._getBrowserName = function (browser) {

  if( (browser !== 'IE') ) {
      return 'notIE';
      //console.log('this is not ie');
    }
};

// UAParserUtil._setBrowserName = function (browser) {
//   var $html = this.$('html');
//   if  {
//     console.log(browser);
//     $html.addClass('mobile');
//   }
// }
