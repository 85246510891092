var AboutUs = (function() {
  // constructor
  var AboutUs = function($) {
    // console.log("AboutUs Constructor");

    this.initAccordion($);
    this.initTab($);
    this.initVideo($);
  };

  var p = AboutUs.prototype;

  p.initAccordion = function ($) {
    MenuUtility.aboutAccrdionMenu($);
  };

  p.initTab = function($) {
    $(".history-list-mobile li").hide();
    $("#tabBox1").css( 'display', 'list-item' );

    $(".history-tab-mobile li a").on("click", function() {
      $(".history-list-mobile li").hide();
      $(".history-tab-mobile li a").removeClass("active");
      $(this).addClass("active");
      $($(this).attr("href")).fadeToggle();
      return false;
    });
  };

  p.initVideo = function($) {


    var embedContainer = $('.embed-container');
    embedContainer.each(function(){
      var thisParent = $(this).parent();
      $(this).html('<iframe src="https://player.vimeo.com/video/'+$(this).parent().data('videoid')+'/?dnt=1&amp;app_id=122963&amp;controls=0&amp;hd=1&amp;autohide=1&amp;portrait=0&amp;title=0&amp;badge=0&amp;color=fff&amp;byline=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" allow="autoplay" data-ready="true"></iframe>');
      var thisIframe = $('iframe',this);
      var player = new Vimeo.Player(thisIframe[0]);
      player.loadVideo(thisParent.data('videoid')).then(function() {
        // the player is ready
        thisParent.addClass('video--loaded');
        $('.play--button.is--pause',thisParent).click(function(){
          if( $(this).hasClass('is--pause') ){
            $(this).removeClass('is--pause');
            player.play();
          }else{
             $(this).addClass('is--pause');
             player.pause();
          }
        });
      });


    });



  };

  return AboutUs;
})();
