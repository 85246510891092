var Common = (function() {
  // constructor
  var Common = function() {
    // console.log("Common Constructor");
    this.init();
  };

  var p = Common.prototype;

  p.init = function() {
    WindowUtility.init();
    this.scroll();
    this.initHamburgerMenu();
    this.setCompanySubPageMenu();
    this.showContents();
  };

  p.initHamburgerMenu = function() {
    MenuUtility.hunbergerMenu();
  };

  p.scroll = function() {
    $(window).scroll(_.debounce(function(){
      WindowUtility.scroll();
    }, 10));
  };

  p.setCompanySubPageMenu = function() {
    //if it's under company page
    // if( $("body").hasClass("parent-pageid-34") ) {
    //   $("li.menu-company").addClass("active");
    //   // $("#index-company").addClass("active");
    //   // $("#index-sustainability").addClass("active");
    // }
  };

  p.showContents = function() {
    this.$main = $("main");
    this.$main.css("display", "block");
    this.$footer = $("footer");
    this.$footer.css("display", "block");
  };

  return Common;
})();
