var Home = (function() {
  var deviceType = "";
  var currentHoverId;

  // constructor
  var Home = function () {
    //console.log("Home Constructor");

    this.init();
  };

  var p = Home.prototype;

  p.init = function () {
    //console.log("Home init");

    this.$window = $(window);
    this.$hero = $("#home-hero");
    this.$heroVimeo = $("#home-hero-vimeo");
    this.$vimeo = $("#vimeo-container");

    if( $('#vimeo-container iframe').length > 0 ){
      this.initVideo();
    }


    this.resizeHero();
    this.initArrow();
    this.moreLatest();

    var lenService = $("#home-service-inner li").length;
    this.initBtnAndImage(lenService, "#homeSerciveListImg", "#homeSerciveListBtn");
    var lenPortfolio = $("#home-portfolio-inner li").length;
    this.initBtnAndImage(lenPortfolio, "#homePortfolioListImg", "#homePortfolioListBtn");
  };

  p.resize = function () {
    this.resizeHero();
  };

  p.resizeHero = function () {
    var height = WindowUtility.getHeight();
    this.$hero.css("height", height + "px");
    this.$heroVimeo.css("height", height + "px");
    if( $('#vimeo-container iframe').length > 0 ){
      this.resizeVideo();
    }
  };

  p.initVideo = function () {
    var iframe = document.querySelector("iframe");

      this.player = new Vimeo.Player(iframe);
      this.player.setVolume(0);
      var parent = this;
      this.player.ready().then(function() {
        var windowWidth = parent.$window.width();
        if( windowWidth > 1024) {
          parent.player.play();
        }
      });

  };

  p.resizeVideo = function () {
    var windowWidth = this.$window.width();
    var windowHeight = this.$window.height();
    var videoWidth = 1280;
    var videoHeight = 720;
    var viewHeight = 520;
    var scaleX = windowWidth / videoWidth;
    var scaleY = windowHeight / viewHeight;
    var scale = scaleX > scaleY ? scaleX : scaleY;
    this.$vimeo.css("width", videoWidth * scale + "px");
    this.$vimeo.css("height", videoHeight * scale + "px");

    this.player.getPaused().then(function(paused) {
      // paused = whether or not the player is paused
      if(paused) {
        if( windowWidth > 1024) {
          //console.log("play");
          this.player.play();
        }
      } else {
        if( windowWidth < 1024) {
          //console.log("pause");
          this.player.pause();
        }
      }
    }).catch(function(error) {
      // an error occurred
    });


    // if( windowWidth < 768) {
    //   this.player.pause();
    // } else {
    //   this.player.play();
    // }


  };

  p.initArrow = function () {
    this.$downBtn = $("#btnDown");
    this.$downBtn.on("click", function (e) {
      var intro = $(".home-intro").position();
      TweenMax.to(window, 1, {scrollTo: {y: intro.top, x: 0}, ease: Power2.easeInOut});
      e.preventDefault();
    });
  };

  p.initBtn = function (btnId, imgArray) {
    var $btn = $(btnId);

    $btn.mouseleave(function(e) {
      //console.log("remove");
      imgArray[currentHoverId].removeClass("active");
      e.preventDefault();
    });

    $btn.mouseenter(function (e) {
      // console.log("hover");
      var num = e.target.getAttribute("data-id");
      for (var i = 0; i < imgArray.length; i++) {
        imgArray[i].removeClass("active");
      }
      imgArray[num].addClass("active");
      currentHoverId = num;
      e.preventDefault();
    });
  };

  p.initBtnAndImage = function (len, imgId, btnId) {
    var imgArray = [];
    for (var i = 0; i < len; i++) {
      imgArray.push($(imgId + i));
      this.initBtn(btnId + i, imgArray);
    }
  };

    p.moreLatest = function () {
    $viewMore = $(".latestLink__more");
    $latestSection = $(".home-latest-section");
    $viewLess = $(".latestLink__less");

    $viewMore.on("click", function (e) {
      e.preventDefault();
      $(this).hide();
      $viewLess.show();
      $latestSection.addClass('feed-show');
    });

    $viewLess.on("click", function (e) {
      e.preventDefault();
      $(this).hide();
      $viewMore.show();
      $latestSection.removeClass('feed-show');
    });
  };

  return Home;
})();
